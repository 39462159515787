import { useGetIdentity } from "@pankod/refine-core";
import { 
  Box, 
  Typography, 
  FormControl, 
  FormHelperText, 
  TextField, 
  Stack,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@pankod/refine-mui"
import { FormProps } from "interfaces/common"
import CustomButton from "./CustomButton"

const Form = ({ type, register, handleSubmit, formLoading, onFinishHandler}: FormProps) => {
  const { data: user } = useGetIdentity();
  
  return (
    <Box>
      <Typography fontSize={25} fontWeight={700} color="#11142d">
        {type} einen Kontakt
      </Typography>
      <Box
        mt={2.5} 
        borderRadius="15px"
        padding="20px"
        bgcolor="#fcfcfc"
      > 
        <form style={{
          marginTop: "20px",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "20px"
        }} onSubmit={handleSubmit(onFinishHandler)}>
          <Stack direction="row" gap={4}>
            <FormControl sx={{ flex: 1}}>
              <FormHelperText sx={{
                fontWeight: 500,
                margin: "10px 0",
                fontSize: 16,
                color: "#11142d"
              }}>Vorname</FormHelperText>
              <TextField 
                fullWidth
                required
                id="outlined-basic"
                color="info"
                variant="outlined"
                placeholder="Max"
                {...register("firstname", { required: true })}
              />
            </FormControl>
            <FormControl sx={{ flex: 1}}>
              <FormHelperText sx={{
                fontWeight: 500,
                margin: "10px 0",
                fontSize: 16,
                color: "#11142d"
              }}>Nachname</FormHelperText>
              <TextField 
                fullWidth
                required
                id="outlined-basic"
                color="info"
                variant="outlined"
                placeholder="Mustermann"
                {...register("lastname", { required: true })}
              />
            </FormControl>
          </Stack>
          <Stack direction="row" gap={4}>
            <FormControl sx={{ flex: 1}}>
              <FormHelperText sx={{
                fontWeight: 500,
                margin: "10px 0",
                fontSize: 16,
                color: "#11142d"
              }}>E-Mail</FormHelperText>
              <TextField 
                type="email"
                fullWidth
                required
                id="outlined-basic"
                color="info"
                variant="outlined"
                placeholder="max.mustermann@online.de"
                {...register("contactEmail", { required: true })}
              />
            </FormControl>
            <FormControl sx={{ flex: 1}}>
              <FormHelperText sx={{
                fontWeight: 500,
                margin: "10px 0",
                fontSize: 16,
                color: "#11142d"
              }}>Telefon</FormHelperText>
              <TextField 
                type="tel"
                fullWidth
                required
                id="outlined-basic"
                color="info"
                variant="outlined"
                placeholder="0173 8373432"
                {...register("phone", { required: true })}
              />
            </FormControl>
          </Stack>
          <Stack direction="row" gap={4}>
            <FormGroup sx={{ flex: 1}}>
              <FormHelperText sx={{
                  fontWeight: 500,
                  margin: "10px 0",
                  fontSize: 16,
                  color: "#11142d"
                }}>Interessiert an Suchprofil:</FormHelperText>
              <FormControlLabel control={<Checkbox color="info" {...register("searchProfile")}/>} label="Ja" />
            </FormGroup>
            <FormGroup sx={{ flex: 1}}>
              <FormHelperText sx={{
                  fontWeight: 500,
                  margin: "10px 0",
                  fontSize: 16,
                  color: "#11142d"
                }}>Interessiert an E-Mails mit Immobilienthemen:</FormHelperText>
              <FormControlLabel control={<Checkbox color="info" {...register("newsletter")}/>} label="Ja" />
            </FormGroup>
          </Stack>
          
          <Stack direction="row" gap={4}>
            <FormControl sx={{ flex: 1 }}>
              <FormHelperText sx={{
                fontWeight: 500,
                margin: "10px 0",
                marginBottom: "0",
                fontSize: 16,
                color: "#11142d"
              }}>Berater
              </FormHelperText>
              <Typography component="p" fontSize={10} color="#11142d">
                Feld wird automatisch befüllt.
              </Typography>
              <TextField 
              fullWidth
              required
              value={user.email}
              id="outlined-basic"
              color="info"
              type="email"
              variant="outlined"
              {...register("berater", { required: true })}
              />
            </FormControl>
          </Stack>
         
          <CustomButton 
            type="submit"
            title={formLoading ? "Wird erstellt..." : "Erstellen"}
            backgroundColor="#475be8"
            color="#fcfcfc"
          />
        </form>
      </Box>
    </Box>
  )
}

export default Form