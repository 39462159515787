import { useApiUrl, useCustom, useGetIdentity, useList } from "@pankod/refine-core";
import {
  Typography,
  Box,
  Stack
} from "@pankod/refine-mui";
import {
  PieChart,
  PropertyReferrals,
  TotalRevenue,
  PropertyCard,
} from "components";
import { useEffect, useState } from "react";
import { InfoOutlined } from "@mui/icons-material";

import { State } from "interfaces/joyRide"
import Joyride, { CallBackProps, STATUS } from 'react-joyride';


const Home = () => {
  const { data: user } = useGetIdentity();

  const { data, isLoading, isError } = useList({
    resource: "properties",
  });

  const latestProperties = data?.data ?? [];

  const [{run, steps}, setJoyState] = useState<State>({
    run: false,
    steps: [
      {
        content: <h2>Wilkommen auf "Dashbaord"</h2>,
        placement: "center",
        target: "body"
      },
      {
        title: "Anzahl der Kunden",
        content: "Hier sehen Sie die Anzahl Ihrer Kunden (Blau) und die Anzahl der weiteren Kunden Ihrer Domäne (Gelb)",
        placement: 'right',
        target: "#customer-pie",
      },
      {
        title: "Anzahl der Deals",
        content: "Hier sehen Sie die Anzahl Ihrer Deals (Blau) und die Anzahl der weiteren Deals Ihrer Domäne (Gelb)",
        placement: 'left',
        target: "#deal-pie",
      },
      {
        title: "Deal Pipeline",
        content: "Hier sehen Sie die aktuelle Anzahl Ihrer Deals (Blau) und die Anzahl der weiteren Deals Ihrer Domäne (Gelb)",
        placement: 'right',
        target: "#pipeline",
      },
      {
        title: "Deal Phasen",
        content: "Hier sehen Sie, wie viele Ihrer Deals in welcher Phase sich befinden.",
        placement: 'left',
        target: "#deal-phase",
      },
      {
        title: "Neuste Angebote",
        content: "Hier sehen Sie die neusten Immobilien Angebote und kommen mit einem Klick auf die WebSeite mit mehr Informationen drauf.",
        placement: 'bottom',
        target: "#offer",
      }
    ]
  });

  const handleTourStart = () => {
    setJoyState(prevState => ({ ...prevState, run: true }));    
  };

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status } = data;
    if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
      setJoyState(prevState => ({ ...prevState, run: false }));    
    }
  };
  
  
  const [myCustomer, setMyCustomer] = useState(0);
  const [othersCustomer, setOthersCustomer] = useState(0);
  const [myDeals, setMyDeals] = useState(0);
  const [othersDeals, setOthersDeals] = useState(0);
  const [allDeals, setAllDeals] = useState<any>();

  const apiUrl = useApiUrl();

  const { data: myUserLength } = useCustom({
    url: apiUrl + "/consultant/users/length/",
    method: "get",
  });

  const domain = user?.email?.split("@")[1];

  const { data: othersUserLength } = useCustom({
    url: apiUrl + "/consultant/users/length/" + domain,
    method: "get",
  });

  const { data: deals } = useCustom({
    url: apiUrl + "/deal/",
    method: "get",
  });

  useEffect(() => {
    if (myUserLength) {
      setMyCustomer(myUserLength.data.length);
    }
  }, [myUserLength]);

  useEffect(() => {
    if (othersUserLength) {
      setOthersCustomer(othersUserLength.data.length);
    }
  }, [othersUserLength]);

  useEffect(() => {
    if (deals) {
      const filteredDeals = deals.data.filteredDeals.filter((deal: { properties: { berater: any; }; }) => {
        const berater = deal.properties?.berater;
        return berater && berater === user.email;
      });

      const myDealsCount = filteredDeals.length;
      const othersDealsCount = deals.data.total - myDealsCount;

      setAllDeals(deals);
      setMyDeals(myDealsCount);
      setOthersDeals(othersDealsCount);
    }
  }, [deals, user]);


  if (isLoading) return <Typography>loading</Typography>;
  if (isError) return <Typography>error</Typography>;

  return (
    <Box>
      <Joyride
        callback={handleJoyrideCallback}
        run={run}
        steps={steps}
        scrollOffset={100}
        continuous
        showSkipButton
        locale={{
          skip: "Überspringen",
          close: "Schließen",
          next: "Weiter",
          back: "Zurück",
          last: "Fertig"
        }}
        styles={{
          options: {
            arrowColor: "white",
            backgroundColor: "white",
            primaryColor: "#3a5777",
            textColor: '#000',
          },
        }}
      />

      <Typography fontSize={25} fontWeight={700} color="#11142D" className="my-dashboard">
        Dashboard   
        <InfoOutlined
          sx={{
            cursor: 'pointer', // This makes the cursor a pointer by default
            '&:hover': {
              // This is a pseudo-selector for the hover state
              cursor: 'pointer', // This ensures the cursor is a pointer during hover as well
              color: "grey"
            }
          }}
          onClick={handleTourStart}
       /> 
      </Typography>
      <Box mt="20px" display="flex" flexWrap="wrap" gap={4}>
        <PieChart 
          id="customer-pie"
          title="Anzahl meiner Kunden"
          value={myCustomer}
          othersTitle="Anzahl gesamt Kunden"
          othersValue={myCustomer + othersCustomer}
          series={[myCustomer, othersCustomer]}
          colors={["#3a5777", "#f5d02f"]} 
          labels={["Meine Kunden", "Weitere Kunden"]}
        />
        <PieChart 
          id="deal-pie"
          title="Anzahl meiner Deals"
          value={myDeals}
          othersTitle="Anzahl gesamt Deals"
          othersValue={myDeals + othersDeals}
          series={[myDeals, othersDeals]}
          colors={["#3a5777", "#f5d02f"]} 
          labels={["Meine Deals", "Weitere Deals"]}
        />
      </Box>
      <Stack
        mt="25px"
        width="100%"
        direction={{ xs: "column", lg: "row"}}
        gap={4}
      >
        {allDeals && allDeals.data ? (
        <>
          <TotalRevenue deals={allDeals.data} />
          <PropertyReferrals deals={allDeals.data} />
        </>
      ) : null}
      </Stack>

      <Box
        id="offer"
        flex={1}
        borderRadius="15px"
        padding="20px"
        bgcolor="#fcfcfc"
        display="flex"
        flexDirection="column"
        minWidth="100%"
        mt="20px"
      >
        <Typography fontSize="18px" fontWeight={600} color="#11142d">Neuste Angebote</Typography>
        <Box mt={2.5} sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 4
        }}>
          {latestProperties.slice(0, 4).map((property) => (
            <PropertyCard 
            key={property.id}
            id={property.id}
            title={property.headline.values[0]}
            price={property.purchaseprice.values[0]}
            location={property.addresses.values[0].city}
            photo={property.mainImage.values[0].uri}
          />
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default Home