import React from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";

import reportWebVitals from "./reportWebVitals";
import App from "./App";


const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN_ADDRESS as string;
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string;

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      redirectUri={window.location.origin}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
