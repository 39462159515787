import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import {
  Button,
  Grid,
  Box,
  Typography,
  Link,
} from "@pankod/refine-mui";
import { main_background, logo_normal } from "assets";

export const Login: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Grid
      container
      style={{ minHeight: "100vh" }}
    >
<Grid
  item
  xs={7}
  style={{
    background: `url(${main_background})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  }}
>
  <Box
    sx={{
      position: "relative",
      top: "30px",
      left: "30px",
    }}
  >
    <img
      src={logo_normal}
      alt="Logo"
      style={{
        maxWidth: "50%",
        marginBottom: "20px",
        cursor: "pointer",
      }}
      onClick={() => window.open('https://rosenstein.immobilien', '_blank')}
    />
  </Box>
</Grid>

      <Grid
        item
        xs={5}
        style={{
          background: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
         <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Box
            sx={{
              bgcolor: "#fff",
              padding: "30px 20px",
              borderRadius: "20px",
              maxWidth: "80%",
            }}
          >
            <Typography
              fontSize={26}
              fontWeight={800}
              color="#11142d"
              marginBottom="20px"
            >
              Hallo!
            </Typography>
            <Typography
              fontSize={18}
              fontWeight={400}
              color="#11142d"
              marginBottom="20px"
            >
              Hier geht's zum Dashboard:
            </Typography>
            <Button
              variant="contained"
              sx={{
                background: "#3a5777",
                "&:hover": {
                  background: "#f5d02f",
                },
                marginBottom: "20px",
              }}
              onClick={() => loginWithRedirect()}
            >
              Weiter
            </Button>

            <Typography fontSize={12} fontWeight={400} color="#11142d">
              Noch keinen Account? Dann fordere dir einen an mit einer Mail an:
            </Typography>
            <Link
              sx={{
                borderColor: "#3a5777",
                color: "#3a5777",
                textDecoration: "none",
                "&:hover": {
                  borderColor: "#f5d02f",
                  color: "#f5d02f",
                },
              }}
              href="mailto:info@rosenstein.immobilien"
            >
              info@rosenstein.immobilien
            </Link>
          </Box>
        </Box>
       
      </Grid>
    </Grid>
  );
};
