import React, { useEffect } from 'react';
import { Box, Stack, Typography } from '@pankod/refine-mui';


const Appointments = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box>
        <Stack 
            direction="column" 
            justifyContent="space-between">
            <Typography fontSize={25} fontWeight={700} color="#11142d">
              Terminvereinbarung
            </Typography>
            <Box mt="20px" className="meetings-iframe-container" data-src="https://meetings-eu1.hubspot.com/philipp-bundschu/beratungstermin-via-plattform-?embed=true" />
        </Stack>
    </Box>
  );
};
export default Appointments 