import { Add } from "@mui/icons-material"
import {  useList } from "@pankod/refine-core"
import { Box, GridColumns, Stack, Typography } from "@pankod/refine-mui"
import { useNavigate } from "@pankod/refine-react-router-v6"
import { DataGrid } from "@pankod/refine-mui"

import { CustomButton } from "components"


const AllContacts = () => {

  const navigate = useNavigate();

  const { data, isLoading, isError } = useList({
    resource: `consultant/users`,
  })
  
  const allContacts = data?.data ?? [];

  const columns: GridColumns = [
    {
        field: "id",
        headerName: "ID",
    },
    { 
        field: "firstname",
        headerName: "Vorname",
        width: 200,
        valueGetter: (params) => params.row.properties.firstname
    },
    { 
        field: "lastname",
        headerName: "Nachname",
        width: 200,
        valueGetter: (params) => params.row.properties.lastname
    },
    { 
        field: "email",
        headerName: "E-Mail",
        width: 300,
        valueGetter: (params) => params.row.properties.email
    },
    { 
        field: "phone",
        headerName: "Telefon",
        width: 200,
        valueGetter: (params) => params.row.properties.phone
    },
   
  ];

  if (isLoading) return <Typography>loading</Typography>
  if (isError) return <Typography>error</Typography>

  return (
    <Box>
        <Stack 
            direction="row" 
            justifyContent="space-between" alignItems="center">
            <Typography fontSize={25} fontWeight={700} color="#11142d">
              Meine Kontakte
            </Typography>
            <CustomButton 
              title="Kontakt"
              handleClick={() => navigate("/contacts/create")}
              backgroundColor="#3a5777"
              color="#fcfcfc"
              icon={<Add />}
            />
        </Stack>

        <Box 
          mt="20px"
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            backgroundColor: "#fcfcfc"
          }}
        >
          <DataGrid
            columns={columns}
            rows={allContacts}
            autoHeight
            
          />
        </Box>
    </Box>
  )
}

export default AllContacts