import React from "react";
import { Refine, AuthProvider } from "@pankod/refine-core";
import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-mui";
import {
  PeopleAltOutlined,
  CalendarMonthOutlined,
  CameraOutdoorOutlined,
  VillaOutlined
} from "@mui/icons-material"

import dataProvider from "@pankod/refine-simple-rest";
import routerProvider from "@pankod/refine-react-router-v6";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { ColorModeContextProvider } from "contexts";
import { Title, Sider, Layout, Header } from "components/layout";
import { 
  Home,
  Login,
  AllContacts,
  CreateContact,
  Tour,
  Appointments,
  AllProperties,
  CreateProperty
} from "pages";


function App() {

  const dataProviderURL = process.env.REACT_APP_BACK_END as string;

  const { isLoading, user, logout, getIdTokenClaims } = useAuth0();

  if (isLoading) {
    return <span>loading...</span>;
  }

  const axiosInstance = axios.create();

  const authProvider: AuthProvider = {
    login: () => {
      return Promise.resolve(false);
    },
    logout: () => {
      logout({ returnTo: window.location.origin });
      return Promise.resolve("/");
    },
    checkError: () => Promise.resolve(),
    checkAuth: async () => {
      try {
        const token = await getIdTokenClaims();
        
        if (token) {
          axiosInstance.defaults.headers.common = {
            Authorization: `Bearer ${token.__raw}`,
          };          
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      } catch (error) {
        return Promise.reject();
      }
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: async () => {
      if (user) {
        return Promise.resolve({
          ...user,
          avatar: user.picture,
        });
      }
      return Promise.reject();
    },
  };

  return (
    <>
      <ColorModeContextProvider>
        <CssBaseline />
        <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
          <RefineSnackbarProvider>
            <Refine
              dataProvider={
                dataProvider(dataProviderURL + "/api/v1", axiosInstance)
              }
              notificationProvider={notificationProvider}
              ReadyPage={ReadyPage}
              catchAll={<ErrorComponent />}
              resources={[
                {
                  name: "contacts",
                  options: {
                    label: "Kunden"
                  },
                  list: AllContacts,
                  create: CreateContact,
                  icon: <PeopleAltOutlined />,
                },
                {
                name: "properties",
                options: {
                  label: "Immobilien"
                },
                list: AllProperties,
                create: CreateProperty,
                icon: <VillaOutlined />
                },
                {
                  name: "appointment",
                  list: Appointments,
                  options: {
                    label: "Termin"
                  },
                  icon: <CalendarMonthOutlined />
                },
                {
                  name: "view",
                  list: Tour,
                  options: {
                    label: "Rundgang"
                  },
                  icon: <CameraOutdoorOutlined />
                },
                // {
                //   name: "my-profile",
                //   options: {
                //     label: "Mein Profil"
                //   },
                //   list: MyProfile,
                //   icon: <AccountCircleOutlined />
                // },
              ]}
              Title={Title}
              Sider={Sider}
              Layout={Layout}
              Header={Header}
              routerProvider={routerProvider}
              authProvider={authProvider}
              LoginPage={Login}
              DashboardPage={Home}
            />
          </RefineSnackbarProvider>
      </ColorModeContextProvider>
    </>
  );
}

export default App;
