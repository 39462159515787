import { useTable } from "@pankod/refine-core"
import { Box, Stack, Typography} from "@pankod/refine-mui"

import { PropertyCard } from "components"

// Methode zum Überprüfen, ob alle erforderlichen Werte vorhanden sind
function isValidProperty(property : any) {
  return (
    property.id &&
    property.headline &&
    property.headline.values &&
    property.headline.values.length > 0 &&
    property.purchaseprice &&
    property.purchaseprice.values &&
    property.purchaseprice.values.length > 0 &&
    property.addresses &&
    property.addresses.values &&
    property.addresses.values.length > 0 &&
    property.addresses.values[0].city &&
    property.mainImage &&
    property.mainImage.values &&
    property.mainImage.values.length > 0 &&
    property.mainImage.values[0].uri
  );
}

const AllProperties = () => {

  const {
    tableQueryResult: { data, isLoading, isError }
  } = useTable();

  const allProperties = data?.data ?? [];
  
  if (isLoading) return <Typography>loading</Typography>
  if (isError) return <Typography>error</Typography>

  return (
    <Box>
        <Stack 
            direction="row" 
            justifyContent="space-between" alignItems="center">
            <Typography fontSize={25} fontWeight={700} color="#11142d">
              Immobilienangebote
            </Typography>
        </Stack>

        <Box mt="20px" sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 3
        }}>
          {allProperties.map((property) => {
            if (isValidProperty(property)) {
              return (
                <PropertyCard
                  key={property.id}
                  id={property.id}
                  title={property.headline.values[0]}
                  price={property.purchaseprice.values[0]}
                  location={property.addresses.values[0].city}
                  photo={property.mainImage.values[0].uri}
                />
              );
            } else {
              return null;
            }
          })}
        </Box>
    </Box>
  )
}

export default AllProperties