import React from 'react'
import { Box, Typography, Stack } from "@pankod/refine-mui"
import { propertyReferralsInfo } from "constants/index"

interface ProgessBarProps {
  title: string,
  percentage: number,
  color: string,
  total: number
}

const ProgessBar = ( {title, percentage, total, color} : ProgessBarProps ) => (
  <Box width="100%">
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Typography fontSize={16} fontWeight={500} color="#11142d">{title}</Typography>
      <Typography fontSize={16} fontWeight={500} color="#11142d">{percentage}</Typography>
    </Stack>
    <Box mt={2} position="relative" width="100%" height="8px" borderRadius={1} bgcolor="#e4e8ef">
      <Box 
        width={`${percentage / total * 100}%`}
        bgcolor={color}
        position="absolute"
        height="100%"
        borderRadius={1}
      />
    </Box>
  </Box>
)

const PropertyReferrals = ( deals: any ) => {
  
  const updatedPropertyReferralsInfo = propertyReferralsInfo.map((info) => ({
    ...info,
    percentage: (deals.deals.countByDealphase[info.title] || 0),
  }));      

  return (
    <Box
      p={4}
      bgcolor="#fcfcfc"
      id="deal-phase"
      minWidth={490}
      display="flex"
      flexDirection="column"
      borderRadius="15px"
    >
    <Typography fontSize={18} fontWeight={600} color="#11142d">
      Meine Deal Phasen
    </Typography>
    <Stack my="20px" direction="column" gap={4}>
      {updatedPropertyReferralsInfo.map((bar) => 
        <ProgessBar key={bar.title} total={deals.deals.total} {...bar}/>
      )}
    </Stack>
    </Box>
  )
}

export default PropertyReferrals