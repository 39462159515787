export const propertyReferralsInfo = [
    {
      title: 'Beratungstermine',
      percentage: 0,
      color: '#6C5DD3',
    },
    {
      title: 'Objekttermine',
      percentage: 0,
      color: '#7FBA7A',
    },
    {
      title: 'Angebotstermine',
      percentage: 0,
      color: '#FFCE73',
    },
    {
      title: 'Offene Angebote',
      percentage: 0,
      color: '#FFA2C0',
    },
    {
      title: 'Laufende Aufträge',
      percentage: 0,
      color: '#F45252',
    },
  ];