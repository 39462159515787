import React from 'react'
import { Box, Typography } from "@pankod/refine-mui"
import ReactApexChart from 'react-apexcharts'
import { TotalRevenueOptions } from "./chart.config"
import { useGetIdentity } from '@pankod/refine-core'


const TotalRevenue =  (deals: any, id: string) => {

  const { data: user } = useGetIdentity();

  // Calculate the sum of amounts for each deal phase
  const sumByDealPhase = deals.deals.filteredDeals.reduce((acc: any, deal: any) => {
  const amount = deal.properties.amount;
  const dealPhase = deal.properties.dealstage;
  const berater = deal.properties.berater;

  if (amount && dealPhase) {
    if (berater === user.email) {
      if (!acc['mine']) {
        acc['mine'] = {
          name: 'Meine Aufträge',
          data: [0, 0, 0, 0, 0],
        };
      }
      const index = ['Beratungstermine', 'Objekttermine', 'Angebotstermine', 'Offene Angebote', 'Laufende Aufträge'].indexOf(dealPhase);
      if (index !== -1) {
        acc['mine'].data[index] += parseInt(amount, 10) / 1000;
      }
    } 
  
    if (!acc['others']) {
        acc['others'] = {
          name: 'Alle Aufträge',
          data: [0, 0, 0, 0, 0],
        };
      }
      const index = ['Beratungstermine', 'Objekttermine', 'Angebotstermine', 'Offene Angebote', 'Laufende Aufträge'].indexOf(dealPhase);
      if (index !== -1) {
        acc['others'].data[index] += parseInt(amount, 10) / 1000;
      }
    }
  

  return acc;
}, {});

// Create the TotalRevenueSeries based on the sumByDealPhase
const TotalRevenueSeries = [
  sumByDealPhase.mine || { name: 'Meine Aufträge', data: [0, 0, 0, 0, 0] },
  sumByDealPhase.others || { name: 'Alle Aufträge', data: [0, 0, 0, 0, 0] },
];

  return (
    <Box
      p={4}
      flex={1}
      bgcolor="#fcfcfc"
      id="pipeline"
      display="flex"
      flexDirection="column"
      borderRadius="15px"
    >
      <Typography fontSize={18} fontWeight={600} color="#11142d">
        Aktuelle Deal Pipeline
      </Typography>

      <Typography fontSize={28} fontWeight={700} color="#11142d">
        € {(TotalRevenueSeries[1].data.reduce((total : number, currentValue : number) => total + currentValue, 0) * 1000).toLocaleString()}
      </Typography>
      <ReactApexChart 
        series={TotalRevenueSeries}
        type="bar"
        height={310}
        width='99%'
        options={TotalRevenueOptions}
      />
    </Box>
  )
}

export default TotalRevenue