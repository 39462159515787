import { ApexOptions } from 'apexcharts';


export const TotalRevenueOptions: ApexOptions = {
  chart: {
    type: 'donut',
    toolbar: {
      show: false,
    },
  },
  colors: ['#3a5777', '#f5d02f'],
  plotOptions: {
    bar: {
      borderRadius: 4,
      horizontal: false,
      columnWidth: '55%',
    },
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    show: false,
  },
  stroke: {
    colors: ['transparent'],
    width: 4,
  },
  xaxis: {
    categories: ['Beratungstermine', 'Objekttermine', 'Angebotstermine', 'Offene Angebote', 'Laufende Aufträge'],
  },
  yaxis: {
    title: {
      text: '€ (Tausend)',
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    position: 'top',
    horizontalAlign: 'right',
  },
  tooltip: {
    y: {
      formatter(val: number) {
        return `€ ${val} Tausend`;
      },
    },
  },
};