import React from 'react'
import { Box, Typography, Stack } from "@pankod/refine-mui"
import { PieChartProps } from "interfaces/home"
import ReactApexChart from 'react-apexcharts'

const PieChart = ({ id, title, value, othersTitle, othersValue, series, 
  colors, labels } : PieChartProps) => {
  return (
    <Box  
      id={id}
      flex={1}
      display="flex"
      bgcolor="#fcfcfc"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      pl={3.5}
      py={2}
      gap={2}
      borderRadius="15px"
      minHeight="110px"
      width="fit-content"
    >
      <Stack direction="column">
        <Typography fontSize={14} color="#808191" >
          {title}
        </Typography>
        <Typography fontSize={24} fontWeight={700} 
          mt={1} color="#11142d" >
          {value}
        </Typography>
      </Stack>

      <Stack direction="column">
        <Typography fontSize={14} color="#808191" >
          {othersTitle}
        </Typography>
        <Typography fontSize={24} fontWeight={700} 
          mt={1} color="#11142d" >
          {othersValue}
        </Typography>
      </Stack>
      

      <ReactApexChart 
        options={{
          chart: { type: "donut"},
          colors,
          legend: { show: false},
          dataLabels: { enabled: false},
          labels: labels
        }}
        type="donut"
        width="120px"
        series={series}
      />
    </Box>
  )
}

export default PieChart