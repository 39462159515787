import { Place } from "@mui/icons-material"
import { Box, Typography, Card, CardMedia, CardContent, Stack } from "@pankod/refine-mui"

import { PropertyCardProps } from "interfaces/property"

const PropertyCard = ({ id, title, location, price, photo}: PropertyCardProps) => {
  return (
    <Card
      sx={{
        maxWidth: "330px",
        padding: "10px",
        "&:hover": {
          boxShadow: "0 22px 45px 2px rgba(176, 176, 176, 0.1)"   
        },
        cursor: "pointer"
      }}
      elevation={0}
      onClick={() => {
        window.open("https://www.rosenstein.immobilien/angebote/" + sanitizeSlug(title), '_blank');
      }}
    >
      <CardMedia 
        component="img"
        width="100%"
        height={210}
        image={photo}
        alt="Card Image"
        sx={{
          borderRadius: "10px"
        }}
      />

      <CardContent sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        gap: "10px",
        paddingX: "5px"
      }}>
        <Stack direction="column" gap={1}> 
          <Typography fontSize={14} fontWeight={500} color="#11142d">{title}</Typography>
          <Stack direction="row" gap={0.5} alignItems="flex-start">
            <Place 
              sx={{
                fontSize: 18,
                color: "#11142d",
                marginTop: 0.5
              }}
            />
            <Typography fontSize={14} color="#808191">{location}</Typography>
          </Stack>
        </Stack>
        <Box px={1.5} py={0.5} borderRadius={1} bgcolor="#dadefa" height="fit-content">
          <Typography fontSize={12} fontWeight={600} color="#475be8">{price}€</Typography>
        </Box>
      </CardContent>
    </Card>
  )
}

function sanitizeSlug(slug: string): string {
  // Definiere ein Record-Typ-Mapping für Umlaute
  const umlautMap: { [key: string]: string } = {
    'ä': 'ae', 'ö': 'oe', 'ü': 'ue', 'ß': 'ss',
    'Ä': 'Ae', 'Ö': 'Oe', 'Ü': 'Ue'
  };

  let sanitizedSlug = slug
    .replace(/[äöüßÄÖÜ]/g, (match) => umlautMap[match as keyof typeof umlautMap]) // Umlaute ersetzen
    .replace(/[^a-zA-Z0-9-_]/g, '-') // Entfernt alle Zeichen außer Buchstaben, Zahlen, - und _
    .replace(/^-+|-+$/g, '') // Entfernt - am Anfang und am Ende
    .toLowerCase(); // Wandelt alles in Kleinbuchstaben um

  return sanitizedSlug;
}


export default PropertyCard