import React, { useEffect } from 'react';
import { Box, Stack, Typography } from '@pankod/refine-mui';

const Tour = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://developer.ogulo.com/js/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box>
        <Stack 
            direction="column" 
            justifyContent="space-between">
            <Typography fontSize={25} fontWeight={700} color="#11142d">
              Virtueller Rundgang
            </Typography>
            <Box mt="20px" height={700}>
              <ogulo-tour tour="0Sow" />
            </Box>
        </Stack>
    </Box>
  );
};
export default Tour 